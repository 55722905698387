import React, { useEffect, useState } from "react";
import { getPageByUrl } from "../data/pages.js";
import { getUser } from "../data/users.js";
import { addToList } from "../data/newsletters.js";
import { useParams } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  Text,
  Image,
  Box
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { SocialIcon } from 'react-social-icons'

const LandingPage = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const { landingPageUrl } = useParams();
  const [page, setPage] = useState(null);
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [showNewsletter, setShowNewsletter] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const page = await getPageByUrl(landingPageUrl);
      const user = await getUser(page.userId);
      setPage(page);
      setUser(user);  
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sendToNewletter = async (event) => {
    event.preventDefault();
    setIsSending(true);
    await addToList(email, page?.newsletterId);
    setShowNewsletter(false);
  };

  let newsletterBlock = null;
  let thanksBlock = null;
  let reviewBlock = null;

  if (page?.newsletterId) {
    newsletterBlock = (
      <Stack spacing={4} align={'center'} paddingTop={5} id="newsletter" display={showNewsletter ? "flex" : "none"}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Sign up for my newsletter
        </Heading>
        <Stack spacing={4} w={'full'} direction={['column', 'row']}>
          <FormControl id="email" isRequired>
            <Input
              placeholder="name@email.com"
              _placeholder={{ color: 'gray.500' }}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isSending}
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" id="newsletter-submit" disabled={isSending} isLoading={isSending}>
            Subscribe
          </Button>
        </Stack>
      </Stack>
    );

    thanksBlock = (
      <Stack spacing={4} align={'center'} paddingTop={5} id="thanks" display={!showNewsletter ? "flex" : "none"}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Thank you for subscribing!
        </Heading>
      </Stack>
    );
  }

  if (page?.reviewLinks?.amazon || page?.reviewLinks?.goodreads) {
    let amazonBlock = null;
    let goodreadsBlock = null;

    if (page?.reviewLinks?.amazon) {
      amazonBlock = (
        <Button colorScheme="orange" onClick={() => openInNewTab(page?.reviewLinks?.amazon)}>
          Review on Amazon
        </Button>
      );
    }

    if (page?.reviewLinks?.goodreads) {
      goodreadsBlock = (
        <Button colorScheme="pink" onClick={() => openInNewTab(page?.reviewLinks?.goodreads)}>
          Review on Good Reads
        </Button>
      );
    }

    reviewBlock = (
      <Stack spacing={4} align={'center'} paddingTop={5}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Leave a review &nbsp;
          <StarIcon color="orange.500" />
          <StarIcon color="orange.500" />
          <StarIcon color="orange.500" />
          <StarIcon color="orange.500" />
          <StarIcon color="orange.500" />
        </Heading>
        <Stack spacing={4} direction={['column', 'row']}>
          {amazonBlock}
          {goodreadsBlock}
        </Stack>
      </Stack>
    );
  }

  return (
    <form onSubmit={sendToNewletter}>
    <Flex h="100vh" padding={10} align="center" justify="center" bg={useColorModeValue('gray.50', 'gray.800')} 
      bgImage={page?.images?.background} bgSize={"cover"} bgPos={"center"}>

    <Stack spacing={4} w={'full'} maxW={'xl'} bg={useColorModeValue('white', 'gray.700')}
      rounded={'xl'} boxShadow={'dark-lg'} p={6} my={12}>

      <Stack align='center'>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          {page?.message?.title}
        </Heading>
        <Image src={page?.images?.hero} maxH={"300px"}/>
        <Text fontSize={{ base: 'sm', lg: 'md' }} color={useColorModeValue('gray.900', 'gray.400')}>
          {page?.message?.text}
        </Text>
      </Stack>

      {newsletterBlock}

      {thanksBlock}

      {reviewBlock}

      <Stack spacing={4} align={'center'} paddingTop={5}>
        <Stack spacing={4} direction={['column', 'row']}>
          {Object.entries(user?.socialMedia ?? []).filter(([social, url]) => url).map(([social, url]) => (
            <SocialIcon key={social} url={url} target="_blank" bgColor="black" style={{ height: 40, width: 40 }}/>
          ))}
        </Stack>
      </Stack>
    </Stack>
    </Flex>
    </form>
  );
};

export default LandingPage;