// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCMdxpm0zrp0wjbeIi1xRat5bp3u6htAbw",
  authDomain: "tomeish-220c9.firebaseapp.com",
  databaseURL: "https://tomeish-default-rtdb.firebaseio.com",
  projectId: "tomeish",
  storageBucket: "tomeish.appspot.com",
  messagingSenderId: "149786016331",
  appId: "1:149786016331:web:e65521169b9878638f6bce"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export { firebaseApp };