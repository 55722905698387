import { firebaseApp } from "../firebase";
import { getFirestore, getDoc, doc } from "firebase/firestore";
const db = getFirestore(firebaseApp);

async function getUser(userId) {
  var user = null;
  const ref = doc(db, "users", userId);
  const snapshot = await getDoc(ref);

  user = snapshot.data();
  user.id = snapshot.id;

  return user;
}

export { getUser };