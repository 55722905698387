// import { firebaseApp } from "../firebase";
// import { getFirestore, collection, getDoc, getDocs, doc, updateDoc, setDoc, query, where, serverTimestamp } from "firebase/firestore";
// const db = getFirestore(firebaseApp);
const fetcher = (...args) => fetch(...args).then((res) => res.json());

async function addToList(email, newsletterId) {
  const url = "https://addemailtolist-he2itdsdqa-uc.a.run.app/" + 
    "?email=" + encodeURIComponent(email) + 
    "&listid=" + newsletterId;

  try {
    const res = await fetcher(url);
    console.log(res);
  } catch (error) {
    console.log(error);
    throw error;
  }

  return;
}

export { addToList };