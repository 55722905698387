import React, { useEffect } from "react";
import {
  Flex,
} from '@chakra-ui/react';

const Home = () => {

  useEffect(() => {
    //getPageByUrl(landingPageUrl).then((page) => setPage(page));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

    
  return (
    <Flex h="100vh" padding={10} align="center" justify="center">
      This is a site
    </Flex>
  );
};

export default Home;