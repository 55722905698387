import { firebaseApp } from "../firebase";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
const db = getFirestore(firebaseApp);

async function getPageByUrl(url) {
  var page = null;
  const q = query(collection(db, "landingPages"), where("url", "==", url));

  try {
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
      page = doc.data();
      page.id = doc.id;
    });
  } catch (error) {
    console.log(error);
  }

  return page;
}

export { getPageByUrl };